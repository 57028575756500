const app = {
    development: {
        API_URI: "http://localhost:3000",
        // API_URI: "https://gymkari.loca.lt",
    },
    staging: {
        API_URI: "https://gymkari-server.herokuapp.com"
    },
    production: {
        API_URI: "https://gymkari.jp",
    }
}
const env = process.env.NODE_ENV || 'development'
export default  app[env]