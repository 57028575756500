class CopyClipboard {

  constructor(){
    this.actionElement = null
    this.textElement = null
  }

  /**
   * 
   * @param {*} actionElement 
   * @param {*} textElement 
   */
  copy(actionElement, textElement){
    this.actionElement = document.getElementById(actionElement)

    this.actionElement && this.actionElement.addEventListener('click', function(){
      this.textElement = document.getElementById(textElement)
      var tempInput = document.createElement("textarea");
      tempInput.value = this.textElement.innerText;
      document.body.appendChild(tempInput);
      tempInput.select();
      document.execCommand("copy");
      document.body.removeChild(tempInput);
      alert('コピーされました')
    })
  }
}

export default new CopyClipboard()
