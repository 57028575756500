import BookingController from './booking/bookingController'
import FixedBody from './utils/fixedBody'
import CopyClipboard from './utils/copy'

$(document).on('turbolinks:load', function () {
  var searchFilterBtn = document.querySelector('#search-filter-btn')
  const body = document.querySelector(".gym-kari-body");
  const closeModalBtn = document.querySelector('#close-filter-modal')
  const filterModal = document.querySelector('#filter-modal')
  const menuIcon = document.querySelector('.menu-icon')
  const hamburgerMenu = document.querySelector('.hamburger-menu')
  const tabs_nav = document.querySelector('.tabs-nav')
  const tabs = document.querySelectorAll('.tabs')
  const sub_nav = document.querySelector('.sub-tabs-nav')
  const sub_tabs = document.querySelectorAll('.sub-tabs')
  const settingContents = document.querySelectorAll('.tab-content')

  // // 設定ナビ
  // tabs_nav && tabs_nav.addEventListener('click', function(e) {
  //     const isTarget = e.target.closest('.tabs')
  //     if (!isTarget) return
  //         // Hidden whole setting tabs
  //     settingContents.forEach((el, index) => {
  //         el.setAttribute('style', 'display: none')
  //     })
  //
  //     tabs.forEach(el => {
  //         el.classList.remove('active')
  //     })
  //
  //     isTarget.classList.add('active')
  //
  //     const tabId = isTarget.getAttribute('href').replace('#', '')
  //
  //     // Display target setting tab
  //     if (tabId) {
  //         const targetTab = document.querySelector(`.tab-content.${tabId}`)
  //         targetTab.setAttribute('style', 'display: block')
  //     }
  // })
  //
  // const currentHash = window.location.hash
  // if(currentHash){
  //     const tabs = document.querySelectorAll('.tabs')
  //     tabs && tabs.forEach(tab =>{
  //         const hash = tab.getAttribute('href')
  //         if(hash === currentHash){
  //             tab.classList.add('active')
  //             const targetTab = document.querySelector(`.tab-content.${hash.replace('#', '')}`)
  //             targetTab.setAttribute('style', 'display: block')
  //         }
  //     })
  // }
  //
  // // オーナーメニューサブナビ
  // sub_nav && sub_nav.addEventListener('click', function(e) {
  //     const isSubTab = e.target.closest('.sub-tabs')
  //     if (!isSubTab) return
  //     const sub_content = document.querySelectorAll('.sub-tab-content')
  //     sub_content.forEach(content => {
  //         content.setAttribute('style', 'display: none')
  //     })
  //     sub_tabs.forEach(el => {
  //         el.classList.remove('active')
  //     })
  //     isSubTab.classList.add('active')
  //     const targetContentId = isSubTab.dataset.toTab
  //
  //     if (targetContentId) {
  //         const targetTab = document.querySelector(`.sub-tab-content.${targetContentId}`)
  //         targetTab.setAttribute('style', 'display: block')
  //     }
  //
  // })


  // ハンバーガーメニューをトグル
  menuIcon && menuIcon.addEventListener('click', function () {
    // disable scrolling body
    if (menuIcon.classList.contains('on')) {
      body.classList.remove("on");
      menuIcon.classList.remove('on')
      hamburgerMenu.classList.remove('on')
      FixedBody.unFixedBody()
    } else {
      body.classList.add("on");
      menuIcon.classList.add('on')
      hamburgerMenu.classList.add('on')
      hamburgerMenu.scrollTop = 0
      FixedBody.fixedBody()

    }

  })

// ハンバーガーメニューの外をクリックする時消える
  window.addEventListener('click', function (event) {
    const hamburgerMenuEl = document.querySelector('.hamburger-menu.on')
    const menuIconEl = document.querySelector('.menu-icon')
    if (hamburgerMenuEl) {
      const body = event.target.closest(".gym-kari-body");
      const hamburgerMenu = event.target.closest('.hamburger-menu.on')
      const menuIcon = event.target.closest('.menu-icon')
      const menuElement = document.querySelector('.hamburger-menu')
      if (!hamburgerMenu && !menuIcon) {
        body.classList.remove("on");
        menuElement.classList.remove('on')
        menuIconEl.classList.remove('on')
        FixedBody.unFixedBody()
      }
    }
  })


//フィルターモーダルを表示する
  searchFilterBtn && searchFilterBtn.addEventListener('click', showModal)
  closeModalBtn && closeModalBtn.addEventListener('click', closeModal)


  function showModal() {
    filterModal.classList.add('open')
    FixedBody.fixedBody()
  }

  function closeModal() {
    filterModal.classList.remove('open')
    FixedBody.unFixedBody()
  }

  window.onresize = resize

  function resize() {
    setMainHeight()
    BookingController.displayAvailableBooking()

  }

// メインの高さを最低設定
  function setMainHeight() {
    var headerHeight = document.getElementsByTagName("HEADER")[0].clientHeight;
    var footer = document.getElementsByTagName("FOOTER")[0]
    var footerHeight = footer ? footer.clientHeight : 0;
    var main = document.getElementsByTagName("MAIN")[0];
    main && main.setAttribute('style', `min-height: calc(100vh - ${headerHeight + footerHeight}px)`)
  }

  setMainHeight()
  BookingController.displayAvailableBooking()
  BookingController.renderLine()

  function flashFadeOut() {
    const elements = document.querySelector('.flash').querySelectorAll('.notice')
    elements.forEach(function (e) {
      $(e).fadeOut('slow');
    })
  }

  setTimeout(flashFadeOut, 800);

  //　招待文言をコピー
  CopyClipboard.copy('invite-copy', 'invite-copy-text')
  // あなたの招待urlをコピー
  CopyClipboard.copy('tap-copy-1', 'copy-target-1')
  // あなたの招待コーデをコピー
  CopyClipboard.copy('tap-copy-2', 'copy-target-2')

  // テキストエリア高さ自動調節
  $('.auto-height').on('change keyup keydown paste cut input', function () {

    if (this.scrollHeight <= this.offsetHeight) {
      this.style.height = "0px";
    }

    this.style.height = `${this.scrollHeight}px`;
    
  });

});
