import { TIMES } from '../config/constant'
import { next60Minutes, next30Minutes, findIndex } from '../utils/utils'
import { OPTION_FULL, OPTION_EVEN, OPTION_ODD } from '../config/constant'
import GymDay from '../utils/day'

class ModalView {

    constructor() {
        this.times = OPTION_FULL
        this.startTimeSelect = document.getElementById('gym-time-begin')
        this.endTimeSelect = document.getElementById('gym-time-end')
        this.dateSelect = document.getElementById('gym-date')
    }

    /**
     * @description 予約モダールが表示の時動的なデータを表示する
     * 
     * @param {*} selectedStart string
     * @param {*} selectedEnd string
     * @param {*} selectedDate date
     */
    renderTime(selectedStart = '', selectedEnd = '', selectedDate) {
        const first_visit = document.querySelector('.container.available-wrapper').dataset.firstVisit
        this.startTimeSelect = document.getElementById('gym-time-begin')
        this.endTimeSelect = document.getElementById('gym-time-end')
        this.dateSelect = document.getElementById('gym-date')

        this.dateSelect.innerHTML = ''
        this.startTimeSelect.innerHTML = ''
        this.endTimeSelect.innerHTML = ''

        const selectedDateObj = GymDay.convertToObject(selectedDate)
        var optionDate = `
        <span value="${selectedDate}">${selectedDateObj.month}月${selectedDateObj.date}日(${selectedDateObj.day})</span>
        <input type="hidden" value="${selectedDate}" id="selectedDate" />
        `
        this.dateSelect.insertAdjacentHTML('afterbegin', optionDate)

        if ( first_visit !== 'true') {
            var next60 = ''
            for (var time of this.times) {
                var optionStart = `<option value="${time}">${time}</option>`
                // var optionEnd = `<option value="${time}">${time}</option>`
                if (selectedStart && time === selectedStart) {
                    optionStart = `<option value="${time}" selected>${time}</option>`
                    next60 = next60Minutes(time)
                }
                // if(next60 === time){
                //     optionEnd = `<option value="${next60}" selected>${next60}</option>`
                // }

                this.startTimeSelect.insertAdjacentHTML('beforeend', optionStart)
                // endTimeSelect.insertAdjacentHTML('beforeend', optionEnd)
            }
            // Render end time options
            this.displayEndTimeOption(selectedStart,next60)
        }
        else {
            var optionStart = `<option value="${selectedStart}">${selectedStart}</option>`
            var next30 = next30Minutes(selectedStart)
            var optionEnd = `<option value="${next30}">${next30}</option>`
            this.startTimeSelect.insertAdjacentHTML('beforeend', optionStart)
            this.endTimeSelect.insertAdjacentHTML('beforeend', optionEnd)
        }

        this.startTimeSelect.addEventListener('change', this.handleChangeSelect.bind(this))
        this.endTimeSelect.addEventListener('change', ()=>{
            this.displayError(null)
        })

    }

    /**
     * @description 予約チェックのエラーを表示する
     * 
     * @param {*} message 
     */
    displayError(message) {
        const errorEl = document.getElementById('reserve-error')
        if(message){
            errorEl.innerText = message
        }else{
            errorEl.innerText = ''
        }
    }

    handleChangeSelect(event) {
        this.displayError(null)
        const selectEnd = next60Minutes(event.target.value)
        this.displayEndTimeOption(event.target.value,selectEnd)
    }

    /**
     * @description reserve-hiddenフォームに予約されたデータをhiddenfieldで生成する
     * @param {*} reservations 
     */
    renderHiddenFields(reservations){
        const reservationsInput = document.getElementById('reservations')
        reservationsInput.value = JSON.stringify(reservations)
    }

    displayEndTimeOption(selectedStart,next60){
        this.endTimeSelect.innerHTML = ''
        var endOptions = parseInt(selectedStart.split(':')[1]) % 2 === 0 ? OPTION_EVEN : OPTION_ODD

        const endIndex = findIndex(endOptions, next60) > 0  ? findIndex(endOptions, next60) : endOptions.length - 1

        for(let i = endIndex; i < endOptions.length; i++){
            var optionEnd = `<option value="${endOptions[i]}">${endOptions[i]}</option>`
            this.endTimeSelect.insertAdjacentHTML('beforeend', optionEnd)
        }
    }
}

export default new ModalView()