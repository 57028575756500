import APIHelper from '../utils/fetch'
import app from '../config'
import {formatDate} from '../utils/format_date'

var config = {
    method: 'get',
    mode: 'cors',
    credentials: 'include',
    headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Credentials': true
    },
}

class BookingService {


    /**
     * 
     * @param {*} id 
     */
    static async getGymJson(id){
        const params = {id : id};
        const query = new URLSearchParams(params);
        const url = `${app.API_URI}/api/gym.json?${query}`;

        const response = await APIHelper.get(url,config)
        return response
    }
    
    /**
     * 
     * @param {*} id 
     * @param {*} date 
     */
    static async getReservations(id, date){
        const params = {id : id, date: formatDate(date, "yyyy-MM-dd")};
        const query = new URLSearchParams(params);
        const url = `${app.API_URI}/api/reservations.json?${query}`
        const response = await APIHelper.get(url,config)
        return response
    }

    /**
     * @description　予約チェックAPIを呼び出す
     * 
     * @param {*} id 
     * @param {*} started_at 
     * @param {*} ended_at 
     */
    static async checkReserveAble(id, started_at, ended_at) {
        const params = {
            id,
            started_at,
            ended_at
        }
        const query = new URLSearchParams(params)

        const url = `${app.API_URI}/api/is_reserve_able?${query}`

        const response = await APIHelper.get(url, config)
        return response
    }
}

export default BookingService