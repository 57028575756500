class APIHelper {

    /**
     * 
     * @param {*} url 
     * @param {*} config 
     */
    async get(url = '', config = {}) {
        
        const response = await fetch(url, config)

        if(response.ok){
            let json = await response.json();
            return json
        }else{
            throw new Error('HTTP-Error: '+ response.status)
        }

    }

    /**
     * 
     * @param {*} url 
     * @param {*} data 
     */
    async post(url = '', data = {}) {
        const response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
        if (response.ok) {
            let json = await response.json();
            return json
        } else {
            throw new Error('HTTP-Error: ' + response.status)
        }
    }
}

export default new APIHelper()