import BookingView from './bookingView'
import GymDay from '../utils/day'
import BookingService from './bookingService'
import FixedBody from '../utils/fixedBody'
import ModalView from './modalView'
import {convertMinutesToDays, getUniqueStr, resolveStartTime, resolveEndTime} from '../utils/utils'
import {formatDate} from '../utils/format_date'


class BookingController {

  constructor() {
    this.currentDatesOfWeek = []
    this.isFirstBooked = 0
    this.reservations = []
    this.reservationMock = {
      reservations: {}
    }
  }

  /**
   *
   * @param {*} gymId
   * @param {*} data
   * @param {*} startDate
   */
  showDataIntoGraph(gymId, data, startDate) {

    const enableBooking = this.calculatorEnableBookingFromNow()

    const startDay = startDate.getDay()
    const max = 6
    var markup = ''

    var currentDatesOfWeek = []

    if (startDay === 0) {
      // from 0 -> 6
      for (var i = 0; i <= 6; i++) {

        markup += this.renderGymAvailable(enableBooking, startDate, data[i])

        currentDatesOfWeek.push(startDate)
        startDate = GymDay.nextDate(startDate)

      }

    } else {
      // from start -> max
      var tempStartDate = startDate
      for (var i = startDay; i <= max; i++) {

        markup += this.renderGymAvailable(enableBooking, tempStartDate, data[i])

        currentDatesOfWeek.push(tempStartDate)
        tempStartDate = GymDay.nextDate(tempStartDate)

      }

      // next start day
      var nextStart = max - startDay + 1
      for (var i = 0; i < nextStart; i++) {
        startDate = GymDay.nextDate(startDate)

      }
      // from 0 -> (start - 1)
      for (var i = 0; i < startDay; i++) {

        markup += this.renderGymAvailable(enableBooking, startDate, data[i])

        currentDatesOfWeek.push(startDate)
        startDate = GymDay.nextDate(startDate)
      }
    }

    BookingView.applyAvailableBookingMarkup(markup)
    BookingView.displayAvailableBooking()

    this.currentDatesOfWeek = currentDatesOfWeek

    // 予約jsonを取得
    BookingService.getReservations(gymId, this.currentDatesOfWeek[0]).then(data => {
      this.reservation = data
      this.prepareReservations()

      // マイ予約を表示する
      for (var d of currentDatesOfWeek) {
        this.showMyBooking(d, this.reservation)
        this.showMyBooking(d, this.reservationMock)
      }

    }).catch(error => {
      console.log(error)
    })

  }

  /**
   * @description 予約データからグラフに表示する
   * @param {*} d Date
   */
  showMyBooking(d, reservation) {
    const reservationsKeys = Object.keys(reservation.reservations)
    for (var date of reservationsKeys) {

      if (GymDay.compareTwoDates(d, new Date(date))) {

        const bookingTimeWrapEl = document.querySelector(`[data-date="${d}"]`)

        for (var timeItem of reservation.reservations[date]) {

          const html = BookingView.renderMyBooking(timeItem)
          bookingTimeWrapEl.insertAdjacentHTML('beforeend', html)
          this.displayAvailableBooking()

        }
      }

    }
  }

  /**
   * @description startDateを起点に一週間の日付を表示する
   * @param {*} startDate Date
   */
  showCalendar(startDate) {

    this.showTimeLine()

    const gymId = document.querySelector('.container.available-wrapper').dataset.gymId
    var mark = ` 
        <div class="day-item">
            <span class="day-item--day">&nbsp;</span>
            <span class="day-item--date"><span class="day-item--month">&nbsp;</span>&nbsp;</span>
        </div>
        `
    const loader = document.querySelector('.gym-booking-loading')

    var startDateObj = GymDay.convertToObject(startDate)

    for (var i = 0; i < 7; i++) {

      // 一週間の日付を作成する
      mark += BookingView.renderDaysOfWeek(startDateObj)

      // 次の日に変更
      startDate = GymDay.nextDate(startDate)
      startDateObj = GymDay.convertToObject(startDate)
    }
    BookingService.getGymJson(gymId).then(data => {
      this.gymData = data
      this.showDataIntoGraph(gymId, this.gymData.data, GymDay.previousWeek(startDate))
      this.ReservationModelProcess()

      loader.style.display = 'none'
    })

    BookingView.applyDayOfWeekMarkup(mark)

  }

  /**
   * @description 00:00から24:00までタイムを表示する
   */
  showTimeLine() {
    BookingView.renderTimeLine()
  }

  /**
   * @description 空き状況を表示する時間帯の高さ単位とstart_timeやend_timeの位置を適当に設定
   */
  displayAvailableBooking() {
    BookingView.displayAvailableBooking()
  }

  /**
   * @description 予約モダールの処理
   */
  ReservationModelProcess() {
    // 空き状況の時間帯をクリックすると予約モーダルを表示する
    const bookingItems = document.querySelectorAll('.booking-item')
    const bookingModal = document.querySelector('.gym-booking-modal')

    const submitBtn = document.getElementById('modal-booking-submit')

    bookingItems.forEach(bookingItem => {
      bookingItem.addEventListener('click', displayModal.bind(this))
    })

    if (bookingModal) {
      bookingModal.addEventListener('click', this.closeModal)
    }

    // 予約ボタンを活性化
    submitBtn.disabled = false
    // 確定ボタンをクリック
    submitBtn.onclick = this.bookingFormController.bind(this)

    function displayModal(event) {

      const bookingItem = event.target.closest('.booking-item')
      const availableBooking = event.target.closest('.booking-item--availability')
      const notAvailableBooking = event.target.closest('.none.booking-item--availability')
      const myBooking = event.target.closest('.my-booking.booking-item--availability')
      const selectedBooking = event.target.closest('.selected-booking.booking-item--availability')
      const first_visit = document.querySelector('.container.available-wrapper').dataset.firstVisit


      if (myBooking || !availableBooking || notAvailableBooking || selectedBooking) return

      if (first_visit === 'true' && this.reservations.length > 0) return

      // 予約モダールを表示する
      this.openModal()

      const selectedDate = new Date(bookingItem.dataset.date)

      var selectedStart, selectedEnd = ""

      if (availableBooking) {
        selectedStart = availableBooking.dataset.begin
        selectedEnd = availableBooking.dataset.end
      }
      // 選択された日付をタイムを表示する
      ModalView.renderTime(selectedStart, selectedEnd, selectedDate)
    }

  }

  closeModal() {

    const bookingModal = document.querySelector('.gym-booking-modal')
    const bookingForm = document.querySelector('.gym-booking-form-wrapper')

    bookingModal.classList.remove('open')
    bookingForm.classList.remove('open')
    FixedBody.unFixedBody()
  }

  openModal() {

    const bookingModal = document.querySelector('.gym-booking-modal')
    const bookingForm = document.querySelector('.gym-booking-form-wrapper')

    ModalView.displayError(null)
    FixedBody.fixedBody()
    bookingModal.classList.add('open')
    bookingForm.classList.add('open')
  }

  /**
   * @description 予約モダールの値を取得
   */
  getBookingModalValue() {
    const selectedDate = document.getElementById('selectedDate').value
    const selectedStart = document.getElementById('gym-time-begin').value
    const selectedEnd = document.getElementById('gym-time-end').value

    return {
      date: selectedDate,
      started_at: selectedStart,
      ended_at: selectedEnd
    }
  }

  /**
   * @description 予約モダールの確定ボタンを押す時のハンドルメソッド
   */
  bookingFormController() {

    const newReservation = this.getBookingModalValue()

    // 予約をチェック
    const gymId = this.gymData.id
    const startedAtString = `${formatDate(new Date(newReservation.date), 'yyyy-MM-dd')}T${newReservation.started_at}:00+09:00`
    const endedAtString = `${formatDate(new Date(newReservation.date), 'yyyy-MM-dd')}T${newReservation.ended_at}:00+09:00`
    const started_at = formatDate(new Date(startedAtString), "yyyy-MM-ddTHH:mm")
    const ended_at = formatDate(new Date(endedAtString), "yyyy-MM-ddTHH:mm")

    // 予約重複クリック制御
    const submitBtn = document.getElementById('modal-booking-submit')
    submitBtn.disabled = true

    BookingService.checkReserveAble(gymId, started_at, ended_at).then(data => {
      var {message, result, gym_id, name} = data
      ModalView.displayError(message)
      if (result === 'OK') {
        const uid = getUniqueStr();

        // Post Request Jsonを生成
        this.generateReservationsRequest(gymId, started_at, ended_at, name, uid)

        // render Hidden field

        ModalView.renderHiddenFields(this.reservations)

        // 上部アイテム表示
        BookingView.renderBookingRenderItem(this.reservations, this.removeCallback.bind(this))

        // 確定された予約を画面描画
        this.renderNewReservation(newReservation, uid)

        // モダールを閉じる
        this.closeModal()

        // 予約へ進めボタンを表示
        // this.isFirstBooked++
        //
        // if (this.isFirstBooked === 1) {
        //     BookingView.renderButton()
        // }

        var go_to_reserve_btn = document.getElementById('go-to-reserve')
        if (this.reservations.length > 0) {
          go_to_reserve_btn.disabled = false
        } else {
          go_to_reserve_btn.disabled = true
        }
      }
      // 予約ボタンを活性化
      submitBtn.disabled = false;
    })
  }


  /**
   *
   * @param {*} newReservation
   * @param {*} uid
   */
  renderNewReservation(newReservation, uid) {
    // 選択された予約のデータを作成
    const currentBookingDate = formatDate(new Date(newReservation.date), "yyyy-MM-dd")

    newReservation[currentBookingDate] = {
      started_at: newReservation.started_at,
      duration: this.convertEndedToDuration(newReservation),
      uid: uid
    }

    if (!this.reservationMock.reservations[currentBookingDate]) {
      this.reservationMock.reservations[currentBookingDate] = []
    }

    this.reservationMock.reservations[currentBookingDate].push(newReservation[currentBookingDate])

    // 画面に表示
    for (var d of this.currentDatesOfWeek) {
      this.showMyBooking(d, this.reservationMock)
    }
  }

  /**
   *
   * @param {*} id
   * @param {*} started_at
   * @param {*} ended_at
   * @param {*} uid
   */
  generateReservationsRequest(id, started_at, ended_at, name, uid) {
    const reservation = {
      id,
      started_at,
      ended_at,
      name,
      uid,
    }
    this.reservations.push(reservation)
  }

  removeCallback(uid) {
    // viewを削除
    BookingView.removeBookingRenderItem(uid);

    this.reservations = this.reservations.filter(function (v) {
      return v.uid != uid;
    });
    ModalView.renderHiddenFields(this.reservations);

    var newMockReservations = {}
    var mockReservations = this.reservationMock.reservations;

    Object.keys(mockReservations).forEach(function (key) {
      var arry = mockReservations[key].filter(function (v) {
        return v.uid != uid;
      });
      newMockReservations[key] = arry;
    });
    this.reservationMock.reservations = newMockReservations;

    var go_to_reserve_btn = document.getElementById('go-to-reserve')
    if (this.reservations.length > 0) {
      go_to_reserve_btn.disabled = false
    } else {
      go_to_reserve_btn.disabled = true
    }
  }

  /**
   *
   * @param { * } reservation
   */
  convertEndedToDuration(reservation) {
    const {started_at, ended_at} = reservation
    const startAtSplit = started_at.split(':')
    const endAtSplit = ended_at.split(':')

    var StarHour = parseInt(startAtSplit[0])
    var startMinutes = parseInt(startAtSplit[1])

    var EndHour = parseInt(endAtSplit[0])
    var EndMinutes = parseInt(endAtSplit[1])

    const periodH = EndHour - StarHour
    const periodM = EndMinutes - startMinutes

    return periodH * 60 + periodM
  }

  /**
   * @description このファンクションは現在時刻からDeadlineとreserve_days_ahead（締め切り）で予約可能時間を計算する
   * @param {
   *  start_at: string
   *  end_at: string
   * } times
   */
  calculatorEnableBookingFromNow(times) {

    const currentTime = {
      day: GymDay.now.getDay(),
      date: GymDay.now.getDate(),
      hours: GymDay.now.getHours(),
      minutes: GymDay.now.getMinutes()
    }
    const {deadline, reserve_days_ahead} = this.gymData
    var nextHead = new Date(GymDay.now)
    nextHead.setDate(nextHead.getDate() + parseInt(reserve_days_ahead))
    const deadlineTime = convertMinutesToDays(deadline)
    var expectDate = GymDay.now
    for (var i = 0; i < deadlineTime.days; i++) {
      expectDate = GymDay.nextDate(expectDate)
    }
    var expM = currentTime.minutes + deadlineTime.minutes
    var expH = currentTime.hours + deadlineTime.hours
    if (expM < 30) {
      expM = 30
    } else {
      expM = 0
      expH += 1
      if (expH > 23) {
        expH = 0
        expectDate = GymDay.nextDate(expectDate)
      }
    }

    const expectTime = {
      day: currentTime.day + deadlineTime.days,
      date: expectDate,
      hours: expH,
      minutes: expM,
      nextHead
    }

    return expectTime
  }

  /**
   *
   * @param {sssss} enableBooking
   * @param {*} currentDate
   * @param {*} data
   */
  renderGymAvailable(enableBooking, currentDate, data) {
    data.start_at = resolveStartTime(data.start_at)
    data.end_at = resolveEndTime(data.end_at)
    const emptyData = {start_at: "00:00", end_at: "00:00"}

    var markup = ''
    if ((new Date(enableBooking.date).getTime() > new Date(currentDate).getTime()) || (new Date(enableBooking.nextHead).getTime() <= new Date(currentDate).getTime())) {
      markup += BookingView.renderBookingAvailable(emptyData, currentDate)
    } else {
      if (GymDay.compareTwoDates(new Date(enableBooking.date), new Date(currentDate))) {

        const startHours = parseInt(data.start_at.split(':')[0])

        if (enableBooking.hours < startHours) {
          markup += BookingView.renderBookingAvailable(data, currentDate)
        } else {
          const newStart_at = `${enableBooking.hours < 10 ? `0${enableBooking.hours}` : enableBooking.hours}:${enableBooking.minutes === 0 ? `0${enableBooking.minutes}` : enableBooking.minutes}`
          data.start_at = newStart_at
          markup += BookingView.renderBookingAvailable(data, currentDate)
        }

      } else {
        markup += BookingView.renderBookingAvailable(data, currentDate)
      }
    }
    return markup
  }


  renderLine() {
    BookingView.renderLineOfWrap()
  }

  /**
   *
   */
  prepareReservations() {
    const reservations = this.reservation.reservations
    let addedReservations = {}
    Object.keys(reservations).map(date => {
      Array.from(reservations[date]).forEach(reservation => {

        function timeToMinutes(time) {
          const [hours, minutes] = time.split(':').map(Number);
          return hours * 60 + minutes;
        }

        const minutesInDay = 1440
        const endTime = timeToMinutes(reservation.started_at) + reservation.duration

        if (endTime > minutesInDay) {
          addedReservations = Object.assign(addedReservations, this.calculatorDuration(date, {
            duration: endTime
          }))

          reservation.duration = minutesInDay - timeToMinutes(reservation.started_at)

        }
      })
    })
    this.reservation.reservations = Object.assign(this.reservation.reservations, addedReservations)
  }

  /**
   *
   * @param {*} date 2021-09-02: string
   */
  checkReservationIsExist(date) {
    return Object.keys(this.reservation.reservations).find(key => key === date)
  }

  /**
   *
   * @param {*} date // 2021-09-02: string
   * @param {*} reservation // {"started_at":"00:00","duration":1440}
   */
  calculatorDuration(date, reservation) {
    const oneDayM = 1440
    const addedReservations = {}
    const {days, hours, minutes} = convertMinutesToDays(reservation.duration)
    let currentDate = new Date(date)

    for (let i = 0; i < days - 1; i++) {
      const nextDate = GymDay.nextDate(currentDate)
      currentDate = nextDate
      const nextDateStr = formatDate(nextDate, 'yyyy-MM-dd')
      if (this.checkReservationIsExist(nextDateStr)) {
        this.reservation.reservations[nextDateStr].push({
          started_at: '00:00',
          duration: oneDayM
        })
      } else {
        addedReservations[nextDateStr] = [{
          started_at: '00:00',
          duration: oneDayM
        }]
      }


    }

    if (hours * 60 + minutes > 0) {
      const nextNextDateStr = formatDate(GymDay.nextDate(currentDate), 'yyyy-MM-dd')
      if (this.checkReservationIsExist(nextNextDateStr)) {
        this.reservation.reservations[nextNextDateStr].push({
          started_at: '00:00',
          duration: hours * 60 + minutes
        })
      } else {
        addedReservations[nextNextDateStr] = [{
          started_at: '00:00',
          duration: hours * 60 + minutes
        }]
      }

    }

    return addedReservations
  }


}

export default new BookingController()