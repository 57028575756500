import {findIndex, resolveStartTime, resolveEndTime} from '../utils/utils'
import {TIMES, TIME_UNIT, TIME_UNIT_HAFT, TIMES_15} from '../config/constant'
import GymDay from '../utils/day'
import {formatDate} from "../utils/format_date";

class BookingView {

  constructor() {
    this.times = TIMES
    this.times15 = TIMES_15
    this.dayParentBox = document.querySelector('.day-box')

    // ジムオーナーの空き状況表示枠
    this.bookingAvailableBox = document.querySelector('.booking-status-box')

  }

  /**
   * @description 各日付に応じるDay objectをhtmlに伝えて、htmlを生成する
   * @param {
   *      day: string,
   *      date: string,
   *      month: string,
   *      year: string,
   * } dayObj
   */
  renderDaysOfWeek(dayObj) {
    return `
        <div class="day-item ${dayObj.day === '土' ? 'saturday' : dayObj.day === '日' ? 'sunday' : ''}">
        <span class="day-item--day">${dayObj.day}</span>
        <span class="day-item--date"><span class="day-item--month">${dayObj.month === 0 ? '12' : dayObj.month}/</span>${dayObj.date}</span>
        </div>
        `
  }

  /**
   *
   * @param {*} markup
   */
  applyDayOfWeekMarkup(markup) {
    this.dayParentBox = document.querySelector('.day-box')

    this.dayParentBox.innerHTML = ""
    this.dayParentBox.insertAdjacentHTML('afterbegin', markup)
  }


  /**
   * @description 各日付に応じる 空き状況 をhtmlに伝えて、htmlを生成する
   * @param {
   *  start_at: string 09:00,
   *  end_at: string 22:00

   * } availableObj
   * @param {*} day
   */
  renderBookingAvailable(availableObj, day) {

    // RESOLVE TIME
    const startTime = resolveStartTime(availableObj.start_at)
    const endTime = resolveEndTime(availableObj.end_at)


    var markup = ''
    const startIndex = findIndex(this.times15, startTime)
    const endIndex = findIndex(this.times15, endTime)

    if (startIndex < endIndex) {
      for (var i = startIndex; i < endIndex; i++) {
        const start = this.times15[i].split(':')[1] % 2 !== 0 ? this.times15[i - 1] : this.times15[i]
        const availabilityMarkup = `
                <div data-start="${start}" data-begin="${this.times15[i]}" data-end="${this.times15[i + 1]}" class="booking-item--availability">
                </div>
                `
        markup += availabilityMarkup
      }
    }

    for (var i = 0; i < this.times15.length; i++) {
      const notAvailable = `
                <div data-begin="${this.times15[i]}" data-end="${this.times15[i + 1]}" class="none booking-item--availability">
                </div>
            `
      markup += notAvailable
    }


    return `
            <div class="booking-item-wrapper">
                <div data-date="${day}" class="booking-item booking-item--1">
                    ${markup}
                </div>
            </div>
        `
  }

  /**
   *
   * @param {*} markup
   */
  applyAvailableBookingMarkup(markup) {
    this.bookingAvailableBox = document.querySelector('.booking-status-box')

    this.bookingAvailableBox.innerHTML = ''
    this.bookingAvailableBox.insertAdjacentHTML('afterbegin', markup)
  }

  /**
   * @description 00:00から25:59までタイミングラインを表示する
   */
  renderTimeLine() {
    const timeBox = document.querySelector('.time-box')
    var ul = document.createElement('ul')
    var mark = ''

    for (var time of this.times) {
      var timeItem = `
            <li class="time-item" style="min-height: ${TIME_UNIT}px">
                ${time}
            </li>
            `
      mark += timeItem
    }

    ul.innerHTML = mark
    if (timeBox) {
      timeBox.innerHTML = ""
      timeBox.insertAdjacentElement('afterbegin', ul)
    }
  }

  renderMyBooking(data) {
    data.ended_at = resolveEndTime(this.convertDurationToEnded(data))
    data.started_at = resolveStartTime(data.started_at)

    if (!data.uid) {
      return `
            <div data-begin="${data.started_at}" data-end="${data.ended_at}" class="selected-booking booking-item--availability">
            </div>
            `
    }

    return `
        <div data-begin="${data.started_at}" data-end="${data.ended_at}" class="my-booking booking-item--availability" data-uid="${data.uid || ''}">
        </div>
        `
  }

  /**
   *  @description 空き状況を表示する時間帯の高さ単位とstart_timeやend_timeの位置を適当に設定
   */
  displayAvailableBooking() {

    const bookingItems = document.querySelectorAll('.booking-item')
    const availableItems = document.querySelectorAll('.booking-item--availability')

    bookingItems && bookingItems.forEach(el => {
      el.setAttribute('style', `height: ${TIME_UNIT * this.times.length - TIME_UNIT_HAFT}px`)
    })

    // 空きの時間帯を表示する
    if (availableItems) {

      availableItems.forEach(availableItem => {
        const begin = availableItem.dataset.begin
        const end = availableItem.dataset.end
        let styleHeight;

        styleHeight = (findIndex(this.times15, end) - findIndex(this.times15, begin)) * TIME_UNIT_HAFT

        if (parseInt(begin.split(':')[1]) % 2 !== 0) {
          availableItem.style.borderTop = 'none'
        }
        if (parseInt(end.split(':')[1]) % 2 !== 0) {
          availableItem.style.borderBottom = 'none'
        }
        const styleTop = (findIndex(this.times15, begin) * TIME_UNIT_HAFT)
        availableItem.style.height = `${styleHeight}px`;
        availableItem.style.top = `${styleTop}px`;
      })
    }
  }

  /**
   *
   * @param {
   *      started_at: string
   *      duration: number
   * } reservation
   */
  convertDurationToEnded(reservation) {
    const {started_at, duration} = reservation
    const startAtSplit = started_at.split(':')

    var hour = parseInt(startAtSplit[0])
    var minutes = parseInt(startAtSplit[1])

    const totalMinutes = minutes + duration
    const period = Math.floor(totalMinutes / 60)

    hour += period

    if (period > 0) {
      minutes = totalMinutes - (60 * period)
    } else {
      minutes = totalMinutes
    }
    return `${hour < 10 ? `0${hour}` : `${hour}`}:${minutes === 0 ? '00' : `${minutes}`}`
  }

  /**
   * @description reserve-formフォームに「予約へ進む」ボタンを追加
   */
  renderButton() {
    const bookingWrapper = document.getElementById('reserve-form')
    const btnMarkup = `
        <div class="booking-action">
            <button type="submit" class="gymkari-btn" id="go-to-reserve">
                 予約へ進む
             </button>
         </div>
        `
    bookingWrapper.insertAdjacentHTML('beforeend', btnMarkup)
  }

  renderBookingRenderItem(reservations, removeCallback) {
    const box = document.getElementById('gym-booking-temp-box')
    box.innerHTML = `<p class="gym-title">選択した日程:</p>`

    let tags = ''

    removeCallback.bind(this);

    reservations.forEach((r, i) => {
      let started_at = new Date(r.started_at + "+09:00");
      let ended_at = new Date(r.ended_at + "+09:00");
      let gymDay = GymDay.convertToObject(started_at);

      tags += `
              <div class="gym-booking-temp__item" data-uid="${r.uid}">
                <span class="gym-booking-temp__item--date">${gymDay.month}月${gymDay.date}日(${gymDay.day})</span>
                <span class="gym-booking-temp__item--time">${formatDate(started_at, "HH")}:${formatDate(started_at, "mm")}-${formatDate(ended_at, "HH")}:${formatDate(ended_at, "mm")}</span>
                <button class="gym-booking-temp__item--close" data-uid="${r.uid}">
                  <img src="https://dwn6kctd0jdpr.cloudfront.net/img/icon/close.png" alt="remove">
                </button>
              </div>
            `
    })

    box.innerHTML += tags;

    let buttons = document.querySelectorAll(".gym-booking-temp__item--close");
    buttons.forEach(function (el, i) {
      const uid = el.dataset.uid;
      el.addEventListener('click', function () {
        removeCallback(uid);
      })
    });
  }

  removeBookingRenderItem(uid) {
    let items = document.querySelectorAll('[data-uid="' + uid + '"]')
    items.forEach(function (el) {
      el.remove();
    })
  }

  /**
   *
   */
  renderLineOfWrap() {
    const bookingWrap = document.querySelector('.booking-status-box__wrap')
    var mark = ""
    for (var i = 0; i < this.times.length; i++) {
      const line = `
                <div style='top: ${i * TIME_UNIT - 1}px' class="booking-box--line">                       
                </div>
            `
      mark += line
    }
    bookingWrap && bookingWrap.insertAdjacentHTML('afterbegin', mark)
  }
}

export default new BookingView()